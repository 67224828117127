.sticky[data-v-dbb73035] {
  position: sticky;
  top: 0px;
  height: 0px;
  z-index: 9;
}
.timeLineDot[data-v-dbb73035] {
  top: 16px;
  width: 10px;
  height: 10px;
  background-color: #1890ff;
  border-radius: 9px;
  margin-left: -25px;
  display: inline-block;
  vertical-align: middle;
  z-index: 9;
}
.timeLineDot[data-v-dbb73035]::before {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 6px;
  content: '';
}
.vline[data-v-dbb73035] {
  height: 100%;
  top: 17px;
  bottom: 0;
  left: 11px;
  width: 2px;
  margin-top: 0;
  background-color: #f5f5f5;
}
.leftLineItem[data-v-dbb73035] {
  display: flex;
  margin-bottom: 15px;
}
.leftLineItem .leftTitle[data-v-dbb73035] {
  position: sticky;
  width: 91px;
  top: 0px;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
}
.leftLineItem .vline[data-v-dbb73035] {
  position: initial;
  height: auto;
  margin-left: 8px;
  margin-top: -20px;
}
.leftLineItem .dotBox[data-v-dbb73035] {
  position: relative;
}
.leftLineItem .dotBox .timeLineDot[data-v-dbb73035] {
  position: sticky;
  top: 0;
  height: 10px;
  margin-left: -6px;
  margin-right: 8px;
  margin-top: 0;
}
.timeLineItem[data-v-dbb73035] {
  position: relative;
  padding-left: 32px;
}
.timeLineItem .timeLineDot[data-v-dbb73035] {
  position: absolute;
  margin-top: -10px;
}
.timeLineItem .vline[data-v-dbb73035] {
  position: absolute;
}
.timeLineItem .leftTitle[data-v-dbb73035] {
  position: sticky;
  top: 0;
  transform: translateX(calc(-100% + 25px));
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  margin: 0px;
  margin-left: -42px;
  height: auto;
}
.timeLineItem .timeName[data-v-dbb73035] {
  position: sticky;
  height: auto;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  top: 0px;
  z-index: 1;
  background-color: #ffffff;
}
.timeLineItem .placeholder[data-v-dbb73035] {
  height: 24px;
}
