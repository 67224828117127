.timeline-container[data-v-2ad7390d] {
  height: 100%;
  overflow-y: scroll;
}
.manageContainer[data-v-2ad7390d] {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}
.manageContainer .manageContent.noBorder[data-v-2ad7390d] {
  border-left: none;
}
.timeLine[data-v-2ad7390d] {
  display: block;
}
.left-margin[data-v-2ad7390d] {
  margin-left: var(--2ad7390d-leftWidth);
}
