.tool-container[data-v-d63c4fbf] {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tool-container .tool[data-v-d63c4fbf] {
  display: flex;
  margin-bottom: 8px;
}
.tool-container .tool[data-v-d63c4fbf] .el-select-tags-wrapper {
  display: flex !important;
}
.tool-container .required[data-v-d63c4fbf] {
  margin-right: 4px;
  color: #f00;
  line-height: 24px;
}
.tool-container .dateSpace[data-v-d63c4fbf] .el-space__item:last-child {
  margin-right: 0px !important;
}
.comment[data-v-d63c4fbf] .el-checkbox__original {
  display: none !important;
}
@media (min-width: 640px) {
.tool[data-v-d63c4fbf] {
    display: flex;
    margin-right: 8px;
}
.tool .company-select[data-v-d63c4fbf] {
    width: 300px;
}
.statistics[data-v-d63c4fbf] {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 2px;
}
.statistics_comment[data-v-d63c4fbf] {
    display: flex;
}
.comment[data-v-d63c4fbf] .el-checkbox {
    margin-bottom: 0px;
    margin-left: 8px;
}
.comment[data-v-d63c4fbf] .el-checkbox__label {
    margin-top: -1px;
}
.search[data-v-d63c4fbf] {
    margin-left: auto;
    margin-bottom: 8px;
}
.dataSelect[data-v-d63c4fbf] .el-input__inner {
    height: 22px !important;
}
}
@media (max-width: 640px) {
.tool[data-v-d63c4fbf] {
    width: 100%;
}
.search[data-v-d63c4fbf] {
    width: 100%;
    margin-left: 11px;
}
.comment[data-v-d63c4fbf] {
    margin-left: 11px;
    margin-bottom: 0px;
    align-items: baseline;
}
.comment[data-v-d63c4fbf] .el-checkbox {
    margin-bottom: 0px;
    margin-left: 8px;
}
[data-v-d63c4fbf] .el-date-editor.el-input {
    width: 100% !important;
}
.dataSelect[data-v-d63c4fbf],
  .dataSelectMultiple[data-v-d63c4fbf] {
    width: 100%;
}
}
